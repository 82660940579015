import React, { useState, useEffect } from 'react';
import { AuthWizardContext } from '../../../../context/Context';
const runtimeConfigFile = '/runtime2.json';

const AuthWizardProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [step, setStep] = useState(1);
  const [date, setDate] = useState('');
  const [defaults, setDefaults] = useState({});
  const [officePhone, setOfficePhone] = useState('');
  const [fieldIds, setFieldIds] = useState({});
  const [db, setDb] = useState('');
  const [availableDates, setAvailableDates] = useState(false);
  const [intervalId, setIntervalId] = useState(0);
  const [customerSettings, setCustomerSettings] = useState(null);
  const [testData, setTestData] = useState(null);

  const handleInputChange = ({ value, name }) => setUser({ ...user, [name]: value });

  useEffect(() => {
    async function getConfig() {
      try {
          console.log(`Getting config`)
          const res = await fetch(runtimeConfigFile); // if not found, return 500
          const data = await res.json();
          // console.log(data);
          setFieldIds(data.fieldIds);
          setTestData(data.test);
          setDb(data.db);
          setCustomerSettings(data.customer);
          setOfficePhone(data.customer.phone);
      }
      catch(e) {
          console.log(e);
      }
    }
    getConfig();
  }, []);  
  
  const value = { 
    user, setUser, 
    step, setStep, 
    date, setDate, 
    handleInputChange, 
    defaults, setDefaults, 
    officePhone, setOfficePhone,
    fieldIds,
    db,
    availableDates, setAvailableDates,
    intervalId, setIntervalId,
    customerSettings,
    testData,
  };

  return <AuthWizardContext.Provider value={value}>{children}</AuthWizardContext.Provider>;
};

export default AuthWizardProvider;
