import React, { Fragment, useContext } from 'react';
import WizardInput from './WizardInput';
import { Col, CustomInput, Row } from 'reactstrap';
import { AuthWizardContext } from '../../../../context/Context';

const ConfirmAddress = ({ register, errors, watch }) => {
  const { customerSettings } = useContext(AuthWizardContext);
  const other = watch('other');
  return (
    <Fragment>
      <p>Please carefully confirm your <strong className="font-weight-bold">job site address</strong> and contact details.</p>
      <WizardInput
        label="Name"
        placeholder="Name"
        name="contactName"
        id="contactName"
        innerRef={register({
          required: 'Name is required',
          minLength: {
            value: 2,
            message: 'Min length 2'
          }
        })}
        errors={errors}
      />
      <WizardInput
        label="Job Site Address"
        placeholder="Job Site Address"
        name="addressLine1"
        id="addressLine1"
        innerRef={register({
          required: 'Address is required',
          minLength: {
            value: 2,
            message: 'Min length 2'
          }
        })}
        errors={errors}
      />
      <WizardInput
        label="City"
        placeholder="City"
        name="city"
        id="city"
        innerRef={register({
          required: 'City is required',
          minLength: {
            value: 2,
            message: 'Min length 2'
          }
        })}
        errors={errors}
      />     
      <Row form>
        <Col>
          <WizardInput
            label="State"
            placeholder="State"
            id="state"
            name="state"
            innerRef={register({
              required: 'You must specify a state',
              minLength: {
                value: 2,
                message: 'Please use 2-character state code'
              }
            })}
            errors={errors}
          />
        </Col>
        <Col>
          <WizardInput
            label="Zip"
            placeholder="Zip"
            id="zip"
            name="zip"
            innerRef={register({
              required: 'You must specify a zipcode',
              minLength: {
                value: 5,
                message: 'Please enter 5-number zipcode'
              }
            })}
            errors={errors}
          />
        </Col>
      </Row>     
      <WizardInput
        label="Email"
        placeholder="Email"
        id="email"
        name="email"
        innerRef={register({
          required: 'Email is required',
          pattern: {
            value: /[A-Za-z0-9._%+-]+@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
            message: 'Email must be valid'
          }
        })}
        errors={errors}
      />
      <WizardInput
        label="Cell Phone"
        placeholder="Cell Phone"
        name="cell"
        id="cell"
        innerRef={register({
          required: 'You must enter a cell phone number',
          minLength: {
            value: 10,
            message: 'Please enter your cell phone number with area code'
          }
        })}
        errors={errors}
      />
      <WizardInput
        type="checkbox"
        id="textOK"
        tag={CustomInput}
        label={
          <Fragment>
            It's OK to text this number
          </Fragment>
        }
        name="textOK"
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      { customerSettings.noroom ? '' : <Fragment>
      <hr />
      <p>In which area or areas are you having countertops installed?<br />(Select all that apply)</p>
      <WizardInput
        type="checkbox"
        id="kitchen"
        name="kitchen"
        tag={CustomInput}
        label={
          <Fragment>
            Kitchen
          </Fragment>
        }
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      <WizardInput
        type="checkbox"
        id="basementKitchen"
        name="basementKitchen"
        tag={CustomInput}
        label={
          <Fragment>
            Basement Kitchen
          </Fragment>
        }
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      <WizardInput
        type="checkbox"
        id="outdoorKitchen"
        name="outdoorKitchen"
        tag={CustomInput}
        label={
          <Fragment>
            Outdoor Kitchen
          </Fragment>
        }
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      <WizardInput
        type="checkbox"
        id="masterBath"
        name="masterBath"
        tag={CustomInput}
        label={
          <Fragment>
            Master Bath
          </Fragment>
        }
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      <WizardInput
        type="checkbox"
        id="hallBath"
        name="hallBath"
        tag={CustomInput}
        label={
          <Fragment>
            Hall Bath
          </Fragment>
        }
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      <WizardInput
        type="checkbox"
        id="kidsBath"
        name="kidsBath"
        tag={CustomInput}
        label={
          <Fragment>
            Kids' Bath
          </Fragment>
        }
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      <WizardInput
        type="checkbox"
        id="laundry"
        name="laundry"
        tag={CustomInput}
        label={
          <Fragment>
            Laundry Room
          </Fragment>
        }
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      <WizardInput
        type="checkbox"
        id="office"
        name="office"
        tag={CustomInput}
        label={
          <Fragment>
            Office
          </Fragment>
        }
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      <WizardInput
        type="checkbox"
        id="commercial"
        name="commercial"
        tag={CustomInput}
        label={
          <Fragment>
            Commercial Space
          </Fragment>
        }
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      <WizardInput
        type="checkbox"
        id="other"
        name="other"
        tag={CustomInput}
        label={
          <Fragment>
            Other
          </Fragment>
        }
        innerRef={register({
          required: false
        })}
        errors={errors}
      />
      { other && <WizardInput
        label="Describe Other Areas"
        placeholder="Other Areas"
        name="otherAreas"
        id="otherAreas"
        innerRef={register({
          required: "Please describe your other areas"
        })}
        errors={errors}
      /> }
      </Fragment> }
    </Fragment>
  );
};

export default ConfirmAddress;
