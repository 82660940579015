import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../../components/common/Toast';

import SurveyLayout from './SurveyLayout';
import ErrorLayout from '../../layouts/ErrorLayout';

const Layout = () => {
  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/errors" component={ErrorLayout} />
        <Route component={SurveyLayout} /> 
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
