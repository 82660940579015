import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './si/helpers/initFA';
import AuthProvider from './si/components/auth/AuthProvider';

ReactDOM.render(
  <Main>
    <AuthProvider>
        <App />
    </AuthProvider>
  </Main>,
  document.getElementById('main')
);
