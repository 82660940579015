import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import Loader from '../../../../components/common/Loader';

// import Lottie from 'react-lottie';
// import animationData from '../../../../components/auth/wizard/lottie/celebration.json';
import { AuthWizardContext } from '../../../../context/Context';

const Success = ({jobPathRoot, guid}) => {
  const { user, date, officePhone, fieldIds, db, intervalId, defaults, customerSettings } = useContext(AuthWizardContext);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false);

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  //   }
  // };

  useEffect(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    async function save() {
      const address = {
        contactName: user.contactName,
        addressLine1: user.addressLine1,
        city: user.city,
        state: user.state,
        zip: user.zip,
        email: user.email,
        cell: user.cell
      }
      const fields = [
        { id: fieldIds.customerEntered, value: 1 }, // customerEntered (aka scheduled)
        { id: fieldIds.date, value: date },
        { id: fieldIds.contactName, value: address.contactName },
        { id: fieldIds.addressLine1, value: address.addressLine1 },
        { id: fieldIds.city, value: address.city },
        { id: fieldIds.state, value: address.state },
        { id: fieldIds.zip, value: address.zip },
        { id: fieldIds.email, value: address.email },
        { id: fieldIds.phone, value: address.cell },
        { id: fieldIds.textOK, value: user.textOK ? 1 : 0 }, // ok to text
        { id: fieldIds.kitchen, value: user.kitchen ? 1 : 0 }, // kitchen
        { id: fieldIds.basementKitchen, value: user.basementKitchen ? 1 : 0 }, // basement kitchen
        { id: fieldIds.outdoorKitchen, value: user.outdoorKitchen ? 1 : 0 }, // outdoor kitchen
        { id: fieldIds.masterBath, value: user.masterBath ? 1 : 0 }, // master bath
        { id: fieldIds.hallBath, value: user.hallBath ? 1 : 0 }, // hall bath
        { id: fieldIds.kidsBath, value: user.kidsBath ? 1 : 0 }, // kids bath
        { id: fieldIds.laundry, value: user.laundry ? 1 : 0 }, // laundry
        { id: fieldIds.office, value: user.office ? 1 : 0 }, // office
        { id: fieldIds.commercial, value: user.commercial ? 1 : 0 }, // commercial
        { id: fieldIds.other, value: user.other ? 1 : 0 }, // other
        { id: fieldIds.otherAreas, value: user.otherAreas || '' }, // other description
        { id: fieldIds.newCabinets, value: user.newCabinets ? 1 : 0 }, // new cabinets
        { id: fieldIds.acknowledgeNewCabinets, value: user.acknowledgeNewCabinets && user.newCabinets ? 1 : 0 }, // new cabinets ack
        { id: fieldIds.existingCabinets, value: user.existingCabinets ? 1 : 0 }, // existing cabinets
        { id: fieldIds.tearout, value: '', valueId: !user.existingCabinets ? '' : (u => {
          switch (u.tearout) {
            case 'tearoutMyself':
              return fieldIds.tearoutMyself;
            case 'tearoutAccent':
              return fieldIds.tearoutAccent;
            case 'tearoutThird':
              return fieldIds.tearoutThird;
            case 'tearoutHard':
              return fieldIds.tearoutHard;
            case 'tearoutSoft':
              return fieldIds.tearoutSoft;
            default:
              return '';
          }
        })(user) }, // existing cabinets removal
        { id: fieldIds.seamsAgreement, value: user.seamsAgreement ? 1 : 0 }, // seams agreement
        { id: fieldIds.graniteSelectionAck, value: user.graniteSelectionAck ? 1 : 0 },
        { id: fieldIds.graniteSelectSlab, value: user.selectSlab ? 1 : 0 },
        { id: fieldIds.digitalLayout, value: user.digitalLayout ? 1 : 0 }, // add digital slabs
        { id: fieldIds.WTEQuartz, value: user.WTEQuartz ? 1 : 0 },
        { id: fieldIds.WTEGranite, value: user.WTEGranite ? 1 : 0 },
        { id: fieldIds.WTESolidSurface, value: user.WTESolidSurface ? 1 : 0 },
        { id: fieldIds.sink, value: '', valueId: (u => {
          switch (u.sink) {
            case 'sinkMyself':
              return fieldIds.sinkMyself;
            case 'sinkAccent':
              return fieldIds.sinkAccent;
            case 'sinkOther':
              return fieldIds.sinkOther;
            default:
              return '';
          }
        })(user) },
        { id: fieldIds.existCabsLevel, value: user.existCabsLevel ? 1 : 0 },
        { id: fieldIds.adultOnSite, value: user.adultOnSite ? 1 : 0},
        { id: fieldIds.RangeAck, value: user.RangeAck ? 1 : 0},
        { id: fieldIds.CustomerSinkAck, value: user.CustomerSinkAck ? 1 : 0},
        { id: fieldIds.FarmSinkAck, value: user.FarmSinkAck ? 1 : 0},
        { id: fieldIds.DualMountSinkAck, value: user.DualMountSinkAck ? 1 : 0},
        { id: fieldIds.readyForMeasure || -1, value: user.readyMeasure ? 1 : 0},
      ];
      const {userAgent} = navigator;
      const update = {fields, address, templateDate: date, userAgent, user, defaults};
      const jobPath = `${jobPathRoot}/${db}/job/${guid}`;
      const result = await fetch(jobPath, {
        method: 'POST',
        body: JSON.stringify(update)
      });
      console.log(result);
      setLoading(false);
      if (result.status !== 200) {
        setError(true);
      }
    }
    console.log(`Success date: ${date}`);
    console.log(user);
    save();
  }, [db, user, date, jobPathRoot, guid, fieldIds, intervalId, defaults]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

//   <div className="wizard-lottie-wrapper">
//   <div className="wizard-lottie mx-auto">
//     <Lottie options={defaultOptions} />
//   </div>
// </div>


  return ( loading ? <Fragment><Loader type='border' /><Row className="flex-center">Sending your selections ...</Row></Fragment> :
    error ? <Fragment>There was a problem saving your information. Please contact your project coordinator to continue.</Fragment> :
    user.readyMeasure ? <Fragment>
      <Row>
        <Col className="">
          <h4 className="mb-1 text-center">You're all set!</h4>
          { 
            date ?
            <p className="fs-0">We will contact you before your template date to confirm the time window.</p> :
            <p className="fs-0">We will contact you soon to schedule your template date and time window.</p>
          }
          { customerSettings.askReadyForMeasure && 
            <p  className="fs-0">Please check your email and complete any required paperwork so that your project moves continuously through our system.</p>
          }
          <p className="fs-0">If you have questions in the meantime, please call {officePhone}.</p>
        </Col>
      </Row>
    </Fragment> :
    <Fragment>
      <Row>
        <Col className="">
          <h4 className="mb-1 text-center">Thank you!</h4>
          <p className="fs-0">Please reach out to us two weeks prior to your desired field measure date so that
            we can schedule your appointment.
          </p>
          <p className="fs-0">If you have questions in the meantime, please call {officePhone}.</p>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Success;
