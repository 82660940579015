import React, {Fragment, useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/Context';

const Logo = ({ at, width, className, ...rest }) => {
  const { isAuthenticated, customer } = useContext(AuthContext);

  return ( 
    <Fragment>
      { customer && customer.linkTitle 
      ? <Link
          to={ isAuthenticated ? '/' : '#' }
          className={classNames(
            'text-decoration-none',
            { 'navbar-brand text-left': at === 'navbar-vertical' },
            { 'navbar-brand text-left': at === 'navbar-top' }
          )}
          {...rest}
        >
          <div
            className={classNames(
              'd-flex',
              {
                'align-items-center py-3': at === 'navbar-vertical',
                'align-items-center': at === 'navbar-top',
                'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth'
              },
              className
            )}
          >
            <span className="text-sans-serif">{customer.title}</span>
          </div>
        </Link>
      : <div
          className={classNames(
            'd-flex',
            {
              'align-items-center py-3': at === 'navbar-vertical',
              'align-items-center': at === 'navbar-top',
              'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth'
            },
            className
          )}
        >
          <span className="text-sans-serif text-primary">{customer.title}</span>
        </div>
      }
    </Fragment>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
