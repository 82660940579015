import React, { Fragment, useContext, useState, useEffect } from 'react';
import { AuthWizardContext } from '../../../../context/Context';
import DayPicker from 'react-day-picker';
import moment from 'moment';

function makeDate(shortDate) {
  const m = shortDate.match(/^(\d+)-(\d+)-(\d+)$/);
  if (!m) return null;
  const year = parseInt(m[1], 10);
  const month = parseInt(m[2], 10)-1;
  const date = parseInt(m[3], 10);
  return new Date(year, month, date);
}

const ChooseDateForm = ({ register, errors, scroll }) => {
  const { user, date, setDate, availableDates, step, setStep } = useContext(AuthWizardContext);

  const [selectedDate, setSelectedDate] = useState();
  const [fromMonth, setFromMonth] = useState(new Date());
  const [disabledDays, setDisabledDays] = useState([ 
    new Date(),
    {before: new Date()}, 
    {daysOfWeek: [0,6]} 
  ]);

  const modStyles = {
    today: {
      fontStyle: "italic"
    }
  }

  const handleSelected = (day, {selected}) => {
    console.log(day);
    console.log(selected);
    setSelectedDate(day);
    setDate(moment(day).format('YYYY-MM-DD'));
  }

  const formattedDate = () => {
    if (!selectedDate) return 'no date';
    return moment(selectedDate).format('dddd, MMMM D');
  }

  useEffect(() => {
    console.log(user);
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, scroll);
  }, [scroll]);

  useEffect(() => {
    function nextStep() {
      setDate('');
      setStep(step+1);
    }
    if (!availableDates) return nextStep();
    console.log(availableDates);
    if (!date) {
      console.log(`No date is currently selected`);
    }
    else if (availableDates[date]) {
      console.log(`${date} is currently available`);
    }
    else {
      console.log(`${date} is NOT currently available - unset it`);
      setDate('');
      setSelectedDate(null);
    }
    const dates = Object.keys(availableDates);
    const goodDates = dates.filter(d => availableDates[d]).sort();
    if (goodDates.length === 0) return nextStep();
    setFromMonth(makeDate(goodDates[0]));
    const badDates = dates.filter(d => !availableDates[d]).sort().map(d => makeDate(d));
    const today = new Date();
    const before = badDates.length ? (badDates[0] > today ? badDates[0] : today) : today;
    const after = badDates[badDates.length-1];

    // for(const d of badDates) {
    //   console.log(d);
    // }

    const newDisabled = [ 
      today,
      ...badDates,
      {before},
      {after},
      {daysOfWeek: [0,6]} 
    ];
    setDisabledDays(newDisabled);

  }, [availableDates, step, setStep, setFromMonth, date, setDate]);

  return ( 
    <Fragment>

        <p>Please choose a date for your template:</p>
        
        <DayPicker 
          month={fromMonth}
          fromMonth={new Date()}
          toMonth={new Date((new Date().getTime() + 1000*60*60*24*100))}
          disabledDays={disabledDays}
          selectedDays={selectedDate}
          onDayClick={handleSelected}
          modifiersStyles={modStyles}
        />

        { 
          selectedDate ? 
          <p>You have chosen <strong>{formattedDate()}</strong>. <em>We will call or text you before this date to confirm a time window for your template.</em></p> :
          <p>Click on a date above ... if you do not select a date, we will call you to schedule your template.</p>
        }

    </Fragment>
  );
};

export default ChooseDateForm;
