import React, { Fragment, useContext, useEffect } from 'react';
import { Card, CardBody} from 'reactstrap';
import { toast } from 'react-toastify';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { AuthContext } from '../../../context/Context';

const Dashboard = () => {

  const {customer} = useContext(AuthContext);

  useEffect(() => { customer && 
    toast(
      <Fragment>
        Welcome to <strong>Template Scheduler</strong> for <strong>{customer.name}</strong>
      </Fragment>
    );
  }, [customer]);

  return (
    <Fragment>
      <div className="card-deck pt-3">
      <Card className="mb-3">
        <FalconCardHeader title={`Template Scheduler | ${customer.name}`} light={false}></FalconCardHeader>
        <CardBody>
          <p>{customer.name} will send you a personalized link to schedule your template when ready.</p>
          <p>In the meantime, if you have any questions, call {customer.phone}.</p>
        </CardBody>
      </Card>
      </div>
    </Fragment>
  );
};

export default Dashboard;
