import React, { Fragment, useState, useContext, useEffect } from 'react';

import WizardInput from './WizardInput';
import WizardError from './WizardError';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import { AuthWizardContext } from '../../../../context/Context';

const Acknowledge = ({ register, errors, clearError, scroll, jobPathRoot, guid }) => {
  const { user, defaults, setAvailableDates, db, intervalId, setIntervalId, customerSettings } = useContext(AuthWizardContext);

  const [newCabinets, setNewCabinets] = useState(('newCabinets' in user) ? user.newCabinets : '');
  const [selectSlab, setSelectSlab] = useState(('selectSlab' in user) ? user.selectSlab : '');
  const [readyMeasure, setReadyMeasure] = useState(('readyMeasure' in user ? user.readyMeasure: ''));
  const readyMeasureChange = (i) => {
    console.log(i.currentTarget.id);
    setReadyMeasure(i.currentTarget.id === 'readyMeasureYes');
  }
  const selectSlabChange = (i) => {
    console.log(i.currentTarget.id);
    setSelectSlab(i.currentTarget.id === 'selectSlabYes');
  }
  const newCabinetsChange = (i) => {
    console.log(i.currentTarget.id);
    setNewCabinets(i.currentTarget.id === 'newCabYes');
  }

  const [existingCabinets, setExistingCabinets] = useState(('existingCabinets' in user) ? user.existingCabinets : '');
  const existingCabinetsChange = (i) => {
    console.log(i.currentTarget.id);
    setExistingCabinets(i.currentTarget.id === 'existCabYes');
  }

  const [tearout, setTearout] = useState(('tearout' in user) ? user.tearout : '');
  const tearoutChange = (i) => {
    setTearout(i.currentTarget.id);
  }

  const [sink, setSink] = useState(('sink' in user) ? user.sink : '');
  const sinkChange = (i) => {
    setSink(i.currentTarget.id);
  }

  const [digitalLayout, setDigitalLayout] = useState(('digitalLayout' in user) ? user.digitalLayout : '');
  const layoutChange = (i) => {
    setDigitalLayout(i.currentTarget.id === 'layoutYes');
  }

  useEffect(() => {
    async function getDates() {
      const jobPath = `${jobPathRoot}/${db}/bad-dates/${guid}`;
      console.log('fetching dates');
      const res = await fetch(jobPath); // if not found, return 500
      console.log(res)
      if (res.ok) {
        const jsonData = await res.json();
        setAvailableDates(jsonData);
      }
    }
    if (db && customerSettings && !customerSettings.noschedule && setAvailableDates && guid && jobPathRoot && !intervalId) {
      getDates();
      setIntervalId(setInterval(getDates, 60000));
    }
  }, [setAvailableDates, db, guid, jobPathRoot, intervalId, setIntervalId, customerSettings]);

  useEffect(() => {
    if (newCabinets !== '') {
      user.newCabinets = newCabinets;
      clearError('newCabRadio');
    }
    if (selectSlab !== '') {
      user.selectSlab = selectSlab;
      clearError('selectSlabRadio');
    }
    if (readyMeasure !== '') {
      user.readyMeasure = readyMeasure;
      clearError('readyMeasureRadio');
    }
    if (existingCabinets !== '') {
      user.existingCabinets = existingCabinets;
      clearError('existingCabRadio');
    }
    if (tearout !== '') {
      user.tearout = tearout;
      clearError('tearoutRadio');
    }
    if (sink !== '') {
      user.sink = sink;
      clearError('sinkRadio');
    }
    if (digitalLayout !== '') {
      user.digitalLayout = digitalLayout;
      clearError('layoutRadio');
    }
  }, [  newCabinets, user.newCabinets,
        selectSlab, user.selectSlab,
        readyMeasure, user.readyMeasure,
        existingCabinets, 
        user.existingCabinets, 
        tearout, user.tearout,
        sink, user.sink,
        digitalLayout, user.digitalLayout,
        clearError
      ]);

  useEffect(() => {
    console.log(defaults);
  }, [defaults]);

  useEffect(() => {
    console.log(user);
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, scroll);
  }, [scroll]);

  return (
    <Fragment>
      <FormGroup>
        <Label>Will any of your countertops be installed on new cabinets?</Label>
        <CustomInput
          type="radio"
          label="Yes"
          id="newCabYes"
          name="newCabRadio"
          checked={newCabinets}
          onChange={newCabinetsChange}
          innerRef={register()}
        />
        <CustomInput
          type="radio"
          label="No"
          id="newCabNo"
          name="newCabRadio"
          checked={newCabinets === false}
          onChange={newCabinetsChange}
          innerRef={register({
            validate: () => { 
              if (newCabinets === '') {
                return 'Please specify whether you have any new cabinets';
              }
              else if (!existingCabinets && !newCabinets) {
                return 'Please specify either new or existing cabinets'
              }
            }
          })}
        />
        <WizardError error={errors['newCabRadio']?.message} className="fs--1 font-weight-normal d-block" />
      </FormGroup>
      { newCabinets && 
        <>
          <p>We can only template once your new cabinets are permanently installed (including all end panels and fillers), and cabinets must be perfectly level before you 
            can schedule your template. Please schedule your template for a date after your cabinet install is completely finished to avoid incurring additional trip charges.</p>
          <WizardInput
            type="checkbox"
            id="acknowledgeNewCabinets"
            tag={CustomInput}
            label={
              <Fragment>
                I acknowledge the above statement on new cabinets
              </Fragment>
            }
            name="acknowledgeNewCabinets"
            innerRef={register({
              required: 'Please acknowledge the above statement on new cabinets'
            })}
            errors={errors}
          />          
        </>
      }
      <hr />
      <FormGroup>
        <Label>Will any of your countertops be installed on existing cabinets?</Label>
        <CustomInput
          type="radio"
          label="Yes"
          id="existCabYes"
          name="existingCabRadio"
          checked={existingCabinets}
          onChange={existingCabinetsChange}
          innerRef={register()}
        />
        <CustomInput
          type="radio"
          label="No"
          id="existCabNo"
          name="existingCabRadio"
          checked={existingCabinets === false}
          onChange={existingCabinetsChange}
          innerRef={register({
            validate: () => { 
              if (existingCabinets === '') {
                return 'Please specify whether you have any existing cabinets';
              }
              else if (!existingCabinets && !newCabinets) {
                return 'Please specify either new or existing cabinets'
              }
            }
          })}
        />
        <WizardError error={errors['existingCabRadio']?.message} className="fs--1 font-weight-normal d-block" />
      </FormGroup>
      { existingCabinets && 
        <Fragment>
          <label>Cabinets must be installed and level!</label>
          <p>
            If you are reusing old cabinets, they must be fully installed and completely level <em>before you can 
            schedule your measure</em> to avoid incurring additional trip charges. If they are not already 
            installed and level, then answer 'No' to the 'Ready for Measure' question below.
          </p>
          <WizardInput
            type="checkbox"
            id="existCabsLevel"
            tag={CustomInput}
            label={
              <Fragment>
                I acknowledge the above statement on existing cabinets
              </Fragment>
            }
            name="existCabsLevel"
            innerRef={register({
              required: 'Please acknowledge the above statement on existing cabinets'
            })}
            errors={errors}
          /> 
        </Fragment> 
      }
      { customerSettings.askReadyForMeasure ? 
        <Fragment>
          <hr />
          <h3>Ready for Measure</h3>
          <FormGroup>
            <Label>Based on the above questions, are you ready for a field measure now?</Label>
            <CustomInput
              type="radio"
              label="Yes"
              id="readyMeasureYes"
              name="readyMeasureRadio"
              checked={readyMeasure}
              onChange={readyMeasureChange}
              innerRef={register()}
            />
            <CustomInput
              type="radio"
              label="No"
              id="readyMeasureNo"
              name="readyMeasureRadio"
              checked={readyMeasure === false}
              onChange={readyMeasureChange}
              innerRef={register({
                validate: () => { 
                  if (readyMeasure === '') {
                    return 'Please specify whether you are ready to measure';
                  }
                }
              })}
            />
            <WizardError error={errors['readyMeasureRadio']?.message} className="fs--1 font-weight-normal d-block" />
          </FormGroup>
        </Fragment> : '' }
      { customerSettings.justCabinets ? '' : <Fragment>
      <hr />
      <FormGroup>
        <Label>Who is providing the sink for this project?</Label>
        <CustomInput
          type="radio"
          label="I am providing the sink"
          id="sinkMyself"
          name="sinkRadio"
          checked={sink === 'sinkMyself'}
          onChange={sinkChange}
          innerRef={register()}
        />
        <CustomInput
          type="radio"
          label="I bought the sink through Accent"
          id="sinkAccent"
          name="sinkRadio"
          checked={sink === 'sinkAccent'}
          onChange={sinkChange}
          innerRef={register()}
        />
        <CustomInput
          type="radio"
          label="Other"
          id="sinkOther"
          name="sinkRadio"
          checked={sink === 'sinkOther'}
          onChange={sinkChange}
          innerRef={register({
            validate: () => sink !== '' || 'Please specify who will be providing the sink'
          })}
        />
        <WizardError error={errors['sinkRadio']?.message} className="fs--1 font-weight-normal d-block" />
      </FormGroup>
      { (sink === 'sinkMyself' || sink === 'sinkOther') &&
      <Fragment>
      <p>When you provide your own sink, you must have it on-site at your template appointment. If it's a Farm Sink, it must be set in its final place, and the top of 
        the farm sink must be level with the top of the cabinet before you can schedule your template. If it's a dual-mount sink, it can only be mounted as a top mount sink.</p>
        { defaults.showCustomerSinkAck && 
          <Fragment>
            <WizardInput
              type="checkbox"
              id="CustomerSinkAck"
              tag={CustomInput}
              label={
                <Fragment>
                  I acknowledge that my sink is on site and ready for the 
                  template appointment
                </Fragment>
              }
              name="CustomerSinkAck"
              innerRef={register({
                required: 'Please acknowledge the above statement'
              })}
              errors={errors}
            /> 
          </Fragment> 
        }
        { defaults.showFarmSinkAck && 
          <Fragment>
            <WizardInput
              type="checkbox"
              id="FarmSinkAck"
              tag={CustomInput}
              label={
                <Fragment>
                  I acknowledge that if my sink is a Farm Sink, it is set in its final place, and the top of 
                  the farm sink is level with the top of the cabinet
                </Fragment>
              }
              name="FarmSinkAck"
              innerRef={register({
                required: 'Please acknowledge the above statement'
              })}
              errors={errors}
            /> 
          </Fragment> 
        }
        { defaults.showDualMountSinkAck && 
          <Fragment>
            <WizardInput
              type="checkbox"
              id="DualMountSinkAck"
              tag={CustomInput}
              label={
                <Fragment>
                  I acknowledge that if my sink is a dual mount sink, it can only be mounted as a top mount sink. 
                  <br /><em>If you would like an undermounted sink, you will need to obtain a new sink prior to scheduling your template appointment.</em>
                </Fragment>
              }
              name="DualMountSinkAck"
              innerRef={register({
                required: 'Please acknowledge the above statement'
              })}
              errors={errors}
            />
          </Fragment> 
        }
      </Fragment>      
      }
      { defaults.showGraniteSelection && 
        <Fragment>
          <hr />
          <label>Selecting Natural Stone</label>
          <p>
          Since every slab of granite is unique, there is a good chance that your slab will look significantly 
          different than the sample you viewed. For this reason, we recommend you select your slab(s) in person. 
          This will require you to visit the distributor you are getting your material from. When you arrive 
          please let them know that your fabricator is Accent Countertops. Not all granite is created equal. 
          Make sure to ask a representative at the slab yard if the granite you are selecting is prone to have 
          a large amount of natural pits, fissures or other imperfections that are inherent in all natural 
          stones. They will be able to point those out for you while you are choosing a slab. 
          </p>
          <WizardInput
            type="checkbox"
            id="graniteSelectionAck"
            tag={CustomInput}
            label={
              <Fragment>
                I acknowledge the above statement on granite selection
              </Fragment>
            }
            name="graniteSelectionAck"
            innerRef={register({
              required: 'Please acknowledge the above statement on seams'
            })}
            errors={errors}
          />
          <FormGroup>
            <Label>Are you planning on selecting your slab in person?</Label>
            <CustomInput
              type="radio"
              label="Yes"
              id="selectSlabYes"
              name="selectSlabRadio"
              checked={selectSlab}
              onChange={selectSlabChange}
              innerRef={register()}
            />
            <CustomInput
              type="radio"
              label="No"
              id="selectSlabNo"
              name="selectSlabRadio"
              checked={selectSlab === false}
              onChange={selectSlabChange}
              innerRef={register({
                validate: () => { 
                  if (selectSlab === '') {
                    return 'Please specify whether you plan to select your slabs in person';
                  }
                }
              })}
            />
            <WizardError error={errors['selectSlabRadio']?.message} className="fs--1 font-weight-normal d-block" />
          </FormGroup>          
        </Fragment> 
      }
      { defaults.showSeamsAgreement && 
        <Fragment>
          <hr />
          <label>Seams Agreement</label>
          <p>
          Hard surface countertops exhibit visible seams. Every effort is made to limit the number of seams. 
          Seam location is designed to be aesthetically pleasing and economically sensitive. Expect large degrees 
          of color variation or pattern at the seam. Final seam placement will be determined during production. 
          Most slabs will not be perfectly flat although all possible measures are taken to ensure tight and 
          smooth seams. Slight variations are to be expected including ledges with the tolerance of the thickness 
          of a business card. 
          </p>
          <WizardInput
            type="checkbox"
            id="seamsAgreement"
            tag={CustomInput}
            label={
              <Fragment>
                I acknowledge the above statement on seams
              </Fragment>
            }
            name="seamsAgreement"
            innerRef={register({
              required: 'Please acknowledge the above statement on seams'
            })}
            errors={errors}
          /> 
        </Fragment> 
      }

      { defaults.showWTEQuartz && 
        <Fragment>
          <hr />
          <label>What to Expect: Quartz / Ultra Compact</label>
          <p>
          To continue, please read <a target="_blank" rel="noopener noreferrer" 
          href="/WTEQuartzAndUltraCompact.pdf">What to Expect for Quartz / Ultra Compact</a>
          </p>
          <WizardInput
            type="checkbox"
            id="WTEQuartz"
            tag={CustomInput}
            label={
              <Fragment>
                I acknowledge reading the above What to Expect document for Quartz / Ultra Compact
              </Fragment>
            }
            name="WTEQuartz"
            innerRef={register({
              required: 'Please acknowledge the above document on What to Expect'
            })}
            errors={errors}
          /> 
        </Fragment> 
      }      
      { defaults.showWTEGranite && 
        <Fragment>
          <hr />
          <label>What to Expect: Granite / Natural Stone</label>
          <p>
          To continue, please read <a target="_blank" rel="noopener noreferrer" 
          href="/WTENaturalStone.pdf">What to Expect for Granite / Natural Stone</a>
          </p>
          <WizardInput
            type="checkbox"
            id="WTEGranite"
            tag={CustomInput}
            label={
              <Fragment>
                I acknowledge reading the above What to Expect document for Granite / Natural Stone
              </Fragment>
            }
            name="WTEGranite"
            innerRef={register({
              required: 'Please acknowledge the above document on What to Expect'
            })}
            errors={errors}
          /> 
        </Fragment> 
      }
      { defaults.showWTESolidSurface && 
        <Fragment>
          <hr />
          <label>What to Expect: Solid Surface</label>
          <p>
          To continue, please read <a target="_blank" rel="noopener noreferrer" 
          href="/WTESolidSurface.pdf">What to Expect for Solid Surface</a>
          </p>
          <WizardInput
            type="checkbox"
            id="WTESolidSurface"
            tag={CustomInput}
            label={
              <Fragment>
                I acknowledge reading the above What to Expect document for Solid Surface
              </Fragment>
            }
            name="WTESolidSurface"
            innerRef={register({
              required: 'Please acknowledge the above document on What to Expect'
            })}
            errors={errors}
          /> 
        </Fragment> 
      }
      <Fragment>
        <hr />
        <label>Digital Slab Layout ($250)</label>
        <p>A digital slab layout will show you details like seam placement and vein direction before your countertops
          are cut. <a target="_blank" rel="noopener noreferrer" 
          href="https://accentcountertops.com/digital-slab-view/">Click here for an example</a>. 
        </p>
        <FormGroup>
          <CustomInput
            type="radio"
            label="Yes, I would like to view a digital slab layout of my project for $250"
            id="layoutYes"
            name="layoutRadio"
            checked={digitalLayout}
            onChange={layoutChange}
            innerRef={register()}
          />
          <CustomInput
            type="radio"
            label="No slab view"
            id="layoutNo"
            name="layoutRadio"
            checked={digitalLayout === false}
            onChange={layoutChange}
            innerRef={register({
              validate: () => { 
                if (digitalLayout === '') {
                  return 'Please specify whether you want a digital slab layout';
                }
              }
            })}
          />
          <WizardError error={errors['layoutRadio']?.message} className="fs--1 font-weight-normal d-block" />
        </FormGroup>
      </Fragment>

      { existingCabinets && 
        <Fragment>
          <hr />
          <FormGroup>
            <Label>Who is taking care of the tear out and removal of your existing countertops?</Label>
            <CustomInput
              type="radio"
              label="Myself"
              id="tearoutMyself"
              name="tearoutRadio"
              checked={tearout === 'tearoutMyself'}
              onChange={tearoutChange}
              innerRef={register()}
            />
            <CustomInput
              type="radio"
              label="Accent"
              id="tearoutAccent"
              name="tearoutRadio"
              checked={tearout === 'tearoutAccent'}
              onChange={tearoutChange}
              innerRef={register()}
            />
            <CustomInput
              type="radio"
              label="Third Party"
              id="tearoutThird"
              name="tearoutRadio"
              checked={tearout === 'tearoutThird'}
              onChange={tearoutChange}
              innerRef={register({
                validate: () => tearout !== '' || 'Please specify who will be performing tearout'
              })}
            />
            <CustomInput
              type="radio"
              label="Add tear out for quartz, granite and/or tile: $13.50/sq ft"
              id="tearoutHard"
              name="tearoutRadio"
              checked={tearout === 'tearoutHard'}
              onChange={tearoutChange}
              innerRef={register()}
            />
            <CustomInput
              type="radio"
              label="Add tear out for laminate and/or solid surface: $9/sq ft"
              id="tearoutSoft"
              name="tearoutRadio"
              checked={tearout === 'tearoutSoft'}
              onChange={tearoutChange}
              innerRef={register()}
            />
            <WizardError error={errors['tearoutRadio']?.message} className="fs--1 font-weight-normal d-block" />
          </FormGroup>
        </Fragment>
      }

      <hr />
      <label>Additional Acknowledgements</label>
      <p>To avoid an incomplete template appointment and incurring additional trip charges, please acknowledge the following:</p>
        <Fragment>
          <WizardInput
            type="checkbox"
            id="adultOnSite"
            tag={CustomInput}
            label={
              <Fragment>
                <strong>I acknowledge</strong> that a decision maker over the age of 18 will be on-site during the entire appointment 
                in order to verify specific details (overhangs, radiuses, backsplash, etc.)
              </Fragment>
            }
            name="adultOnSite"
            innerRef={register({
              required: 'Please acknowledge the above statement'
            })}
            errors={errors}
          /> 
        </Fragment> 
      { defaults.showRangeAck && 
        <Fragment>
          <WizardInput
            type="checkbox"
            id="RangeAck"
            tag={CustomInput}
            label={
              <Fragment>
                <strong>I acknowledge</strong> that if my range or cooktop is part of or next to any new countertop, then it will be 
                onsite at the template appointment
              </Fragment>
            }
            name="RangeAck"
            innerRef={register({
              required: 'Please acknowledge the above statement'
            })}
            errors={errors}
          /> 
        </Fragment> 
      }
      </Fragment> }
    </Fragment>
  );
};

export default Acknowledge;
