import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../context/Context';
import Amplify, {Auth} from 'aws-amplify';
const runtimeConfigFile = '/runtime2.json';

const cognito = false;

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(true); // QUICK AND DIRTY
  const [isAuthenticating, setAuthenticating] = useState(false); // QUICK AND DIRTY
  const [userEmail, setUserEmail] = useState('');
  const [initialUser, setInitialUser] = useState({});
  const [customer, setCustomer] = useState('');
  const [error, setError] = useState(false);
  const [jobs, setJobs] = useState({}); // use for config that goes on Home
  const [accounts, setAccounts] = useState({}); // use for config that goes on Home
  const [masklogo, setMasklogo] = useState(false); // use for config that goes on Home

  useEffect(() => {
    async function getConfig() {
      try {
          const res = await fetch(runtimeConfigFile); // if not found, return 500
          const data = await res.json();
          // console.log(data);
          setCustomer(data.customer);
          // setCognito(data.cognito); QUICK AND DIRTY
          setJobs(data.jobs);
          setAccounts(data.accounts);
          setMasklogo(data.masklogo ?? false);
      }
      catch(e) {
          console.log(e);
          setError(true);
      }
    }
    getConfig();
  }, []);

  useEffect(() => {
    if (!customer) {
      return;
    }
    document.title = `Schedule Your Template | ${customer.name}`;
  }, [customer])

  useEffect(() => {
    if (!cognito) {
      return;
    }

    function configure() {
      Amplify.configure({
        Auth: {
          mandatorySignIn: true,
          region: cognito.REGION,
          userPoolId: cognito.USER_POOL_ID,
          userPoolWebClientId: cognito.APP_CLIENT_ID,          
          
          cookieStorage: (window.location.hostname === "localhost" || window.location.hostname.match(/^10.0.0*/)) ? false : {
                domain: window.location.hostname,
                path: '/',
                expires: 365,
                sameSite: "lax",
                secure: true
            },
          
        }
      });      
    }

    async function getCurrentUser() {
      try {
        const sess = await Auth.currentSession(); // if there's no user, it will throw exception with 'No current user' which is handled below
        console.log('Session:');
        console.log(sess);
        setAuthenticated(true);
        const user = await Auth.currentUserInfo();
        setUserEmail(user?.attributes?.email);
      }
      catch(e) {
        if (e !== 'No current user') {
          console.log(e); // this is something unknown that I'll want to know about
        }
        else {
          console.log(e); // same thing, but it's an expected condition :)
        }
        setAuthenticated(false);
      }
      setAuthenticating(false);
    }
    configure();
    getCurrentUser();
  }, []);

  const login = async (email, password) => {
    try {
      const user = await Auth.signIn(email, password);

      console.log(user);
      setUserEmail(email);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setInitialUser(user);
        return 'change-password';
      }

      setAuthenticated(true);
      return 'success';
    }
    catch (e) {
      console.log(e);
      if (e.message === 'User is not confirmed.') {
        // need to save credentials temporarily, so that we can login after confirmation
      }
      return e.message;
    }
  }

  const logout = async () => {
    await Auth.signOut();
    setAuthenticated(false);
  }

  const completeAuth = async (newPassword) => {
    try {
      await Auth.completeNewPassword(initialUser, newPassword);
      // succeeded, so now login with the new password
      await login(userEmail, newPassword);
      return 'success'
    }
    catch(e) {
      console.log(e);
      return e;
    }
  }

  const forgotPassword = async (email) => {
    try {
      const result = await Auth.forgotPassword(email);
      console.log(result);
    }
    catch(e) {
      console.log(e);
      return e;
    }    
  }
  
  const value = {
    isAuthenticated,
    setAuthenticated,
    isAuthenticating,
    setAuthenticating,
    login,
    logout,
    userEmail,
    completeAuth,
    forgotPassword,
    customer,
    jobs,
    accounts,
    masklogo,
  };

  return error ? <div>Configuration error</div> : <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = { children: PropTypes.node.isRequired };

export default AuthProvider;
