import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import ErrorLayout from '../../layouts/ErrorLayout';

import WizardLayout from '../components/auth/wizard/WizardLayout';

const SurveyLayout = ({ location }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className='container'>
      <>
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/schedule/:guid" component={WizardLayout} />
            <Route path="/errors" component={ErrorLayout} />

            <Redirect to="/errors/404" />
          </Switch>
      </>
    </div>
  );
};

export default SurveyLayout;
