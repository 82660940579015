import React from 'react';
import { Col, Row } from 'reactstrap';
import UserForm from './UserForm';
import Section from '../../../../components/common/Section';
// import Logo from '../../navbar/Logo';
import AuthWizardProvider from './AuthWizardProvider';

const WizardLayout = (props) => {
  const { guid } = props.match.params;
  console.log(guid);
  return (
    <Section className="py-0">
      <Row className="flex-center">
        <Col sm={10} lg={7} className="col-xxl-5">
          <AuthWizardProvider>
            <div className="text-center">
              <a href="https://tcfmidwest.com">
                <img
                  width="188"
                  src="/logo.png"
                  className="py-3"
                  alt="Logo"
                />
              </a>
            </div>
            <UserForm guid={guid} />
          </AuthWizardProvider>
        </Col>
      </Row>
    </Section>
  );
};

export default WizardLayout;
